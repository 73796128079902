import React from "react"
import { Content } from "../content/Content"
import { Button } from "../button"
import { Image } from "../image/Image"
import { PriceTier } from "./PriceTier"
import { ImageBuilder } from "_types/AssetsImage"
import { ButtonBuilder } from "_types/Button"
import { RichTextContent } from "_types/RichText"
import { SinglePriceTier } from "./price-tier-types"

interface SingleServiceProps {
  name?: string
  serviceFeatures?: RichTextContent
  ctaButton?: ButtonBuilder
  pestsCovered?: RichTextContent
  priceTerm?: string
  priceTiers?: SinglePriceTier
  featuredImage?: ImageBuilder
  serviceCount: number
}

export const SingleService = ({
  name,
  serviceFeatures,
  ctaButton,
  pestsCovered,
  priceTerm,
  priceTiers,
  featuredImage,
  serviceCount,
}: SingleServiceProps) => {
  const altBg = serviceCount % 2 ? false : true
  return (
    <section
      className={
        altBg ? `single-service single-service--alt-bg` : `single-service`
      }
    >
      <div className="fr-container fr-container--large u-clearfix ">
        {name && <h2 className="single-service__title">{name}</h2>}
        <div className="single-service__pricing">
          {featuredImage && (
            <Image
              image={featuredImage}
              width={featuredImage.width}
              className="single-service__image"
            />
          )}
          {priceTiers && (
            <PriceTier
              priceTier={priceTiers.elements}
              priceTerm={priceTerm || "monthly"}
            />
          )}
        </div>
        <div className="single-service__content">
          {serviceFeatures && serviceFeatures.value !== "<p><br></p>" && <Content content={serviceFeatures} />}
          {pestsCovered && pestsCovered.value !== "<p><br></p>" && <Content content={pestsCovered} />}
        </div>
        {ctaButton && (
          <div className="single-service__button">
            <Button variant="solid-primary">
              {ctaButton.elements.button_text.value}
            </Button>
          </div>
        )}
      </div>
    </section>
  )
}
