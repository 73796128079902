import React from 'react'

interface FAQQuestionProps {
  children: string
}

export const FAQQuestion = ({children}: FAQQuestionProps) => {
  return (
    <h3 itemProp="name" className="fr-faq-group-block__question">
        {children}
    </h3>
  )
}