import React from "react"
import { Link, graphql } from "gatsby"
import { ImageElement } from "@kentico/gatsby-kontent-components"
import { VscTriangleRight } from "react-icons/vsc"
import "./simple-tile.module.scss"
import { useColorAreaSettings } from "../../hooks/useColorAreaSettings"
import { TileElements } from "./simple-tile-types"

interface SimpleTileProps {
  tile: TileElements
  parentSlug: string
}

export const SimpleTile = ({ tile, parentSlug }: SimpleTileProps) => {
  const {
    slug_generator,
    seo_metadata__rich_data_image,
    slug,
    seo_metadata__overlay_cta_text,
  } = tile

  const { navTileColorOverlay } = useColorAreaSettings()
  const overrideBackground = navTileColorOverlay
    ? `${navTileColorOverlay}--backgroundColorGradient`
    : ""

  const getSlug = () => {
    let finalSlug
    if (parentSlug === "" || parentSlug === "/") {
      finalSlug = `/${slug?.value}`
    } else {
      finalSlug = `/${parentSlug}/${slug?.value}`
    }
    return finalSlug
  }

  return (
    <Link to={getSlug()} className="tile">
      {seo_metadata__rich_data_image?.value.length > 0 && (
        <div className="tile__body">
          <ImageElement
            image={seo_metadata__rich_data_image.value[0]}
            className="tile__image"
          />
          <div className={`tile__overlay ${overrideBackground}`}></div>
          <p className="tile__overlay-cta">
            {seo_metadata__overlay_cta_text?.value || "Learn More"}
            <VscTriangleRight className="tile__overlay-triangle" />
          </p>
        </div>
      )}
      <div className="tile__footer-wrapper">
        <h3 className="tile__footer">{slug_generator.value}</h3>
      </div>
    </Link>
  )
}

export const query = graphql`
  fragment SimpleTileFragment on kontent_item_legacy_pest_library {
    id
    system{
      type
    }
    elements {
      pages {
        value {
          ... on kontent_item_page {
            id
            elements {
              slug {
                value
              }
              slug_generator {
                value
              }
              seo_metadata__rich_data_image {
                value {
                  url
                  width
                  height
                }
              }
              seo_metadata__overlay_cta_text {
                value
              }
            }
          }
        }
      }
    }
  }
`
