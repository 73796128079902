import React, { ReactNode } from 'react'
import { useAccordion, useAccordionItem } from 'contexts/components/AccordionContext'
import "./accordion.module.scss"

interface AccordionPanelProps {
  children: ReactNode
}

export const AccordionPanel = ({children}: AccordionPanelProps) => {
  const { activePanel, isFAQ } = useAccordion()
  const { id } = useAccordionItem()

  const isOpen = id === activePanel ? " fr-accordion__panel--open" : ""

  const faqProps = isFAQ ? {
    itemScope: true,
    itemProp: "acceptedAnswer",
    itemType: "https://schema.org/Answer"
  } : {}

  const faqTextWrapper = isFAQ ? {
    itemProp: "text"
  } : {}

  return (
    <div aria-hidden={id === activePanel ? "false" : "true"} className={`fr-accordion__panel${isOpen}`} {...faqProps}>
      <div className="fr-accordion__panel-text" {...faqTextWrapper}>
        {children}
      </div>
    </div>
  )
}