import React, { ReactElement, useState, useCallback } from 'react';
import { AccordionContext } from 'contexts/components/AccordionContext';
import "./accordion.module.scss"

interface AccordionProps {
  children: ReactElement | ReactElement[]
  isFAQ?: boolean
  firstActivePanel?: string
}

export const Accordion = ({children, firstActivePanel = "", isFAQ = false}: AccordionProps) => {
  const [activePanel, setActivePanel] = useState(firstActivePanel)

  const handlePanelClick = useCallback((id: string) => {
    setActivePanel(state => state !== id ? id : '')
  }, [])

  return (
    <AccordionContext.Provider value={{
      activePanel,
      handlePanelClick,
      isFAQ
    }}>
      <div className='fr-accordion'>
        {children}
      </div>
    </AccordionContext.Provider>
  )
}
// How to use this compound component
{/* <Accordion>
  <AccordionItem id="1">
    <AccordionToggle>Section #1</AccordionToggle>
    <AccordionPanel>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. In dolor itaque quod quae amet, nemo harum iure! Recusandae doloribus tenetur, asperiores libero quisquam dolor ipsum, debitis, earum ut iusto sit.
    </AccordionPanel>
  </AccordionItem>
</Accordion> */}
