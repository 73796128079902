import React from "react"
import { graphql } from "gatsby"
import { Highlights } from "components/highlights/Highlights"
import { ServiceComparison } from "components/service-comparison/ServiceComparison"
import { Gallery } from "components/image-gallery/Gallery"
import { AffiliationSlider } from "components/affiliation-slider/AffiliationSlider"
import { MultiContentBlocks } from "components/multi-content-block/MultiContentBlocks"
import { NavigationTiles } from "components/navigation-tile/NavigationTiles"
import { FormBuilder } from "components/forms/FormBuilder"
import { TextContentBlock } from "./TextContentBlock"
import { TextAndImageContentBlock } from "./TextAndImageContentBlock"
import { Image } from "components/image/Image"
import { SingleService } from "components/service-comparison/SingleService"
import { FAQGroupBanner } from "components/faq/FAQGroupBanner"
import { Block } from "./content-blocks-types"
import { HtmlEmbed } from "components/html-embed/HtmlEmbed"
import { TabbedContentBlockBanner } from "components/tabbed-content-banner/TabbedContentBlockBanner"
import { CouponBanner } from "components/coupons/CouponBanner"
import { FAQGroupBlock } from "components/faq/FAQGroupBlock"
import "./content-block.module.scss"
import { SimpleTileList } from "components/simple-tile/SimpleTileList"

interface ContentBlockParserProps {
  blocks: Block & { [key: string]: any }
  position: number
  blockCount: number
  mapRuns: number
  isAltBlock: boolean
  serviceCount: number
  legacyNavGridHasAltBackground?: boolean
}

export const ContentBlockParser = ({
  blocks,
  mapRuns,
  isAltBlock,
  serviceCount,
  legacyNavGridHasAltBackground,
}: ContentBlockParserProps) => {
  // We are destructuring here only what's common at a global level
  const {
    heading,
    sub_heading,
    alt_background_color,
    eyebrow_text,
    // html, -> Move this to a local scoped level like the other components
  } = blocks

  const hasAltBackgroundColor =
    alt_background_color?.value[0]?.codename === "yes" ? true : false

  const getHighlights = () => {
    const { highlights, eyebrow_text } = blocks
    if (highlights) {
      return (
        <Highlights
          eyebrowText={eyebrow_text?.value}
          heading={heading?.value}
          subHeading={sub_heading?.value}
          highlights={highlights.modular_content}
          hasAltBackgroundColor={hasAltBackgroundColor}
        />
      )
    }
  }

  const getSingleService = () => {
    const {
      service_features,
      name,
      pests_covered,
      price_term,
      button,
      price_tiers,
      image,
    } = blocks
    if (service_features) {
      return (
        <SingleService
          name={name?.value}
          serviceFeatures={service_features}
          pestsCovered={pests_covered}
          priceTerm={price_term?.value[0].codename}
          ctaButton={button?.value[0]}
          priceTiers={price_tiers?.value[0]}
          serviceCount={serviceCount}
          featuredImage={image?.value[0]}
        />
      )
    }
  }

  const getFormBuilder = () => {
    const {
      fields,
      submit_button_text,
      submission_message_body,
      submission_message_heading,
      custom_id,
      disclaimer,
    } = blocks
    const customId = custom_id?.value === "" ? "form-banner" : custom_id?.value
    if (fields) {
      return (
        <FormBuilder
          heading={heading?.value}
          subHeading={sub_heading?.value}
          ctaText={submit_button_text?.value}
          submissionMessageHeading={submission_message_heading?.value}
          submissionMessageBody={submission_message_body?.value}
          fields={fields}
          formGridStyle={"form-grid-3"}
          customId={customId}
          disclaimer={disclaimer.value}
        />
      )
    }
  }

  const getTextContentBlock = () => {
    const { eyebrow_text, content, image, buttons, style_lists_as_grid } =
      blocks
    const styleListsAsGrid =
      style_lists_as_grid?.value[0]?.codename === "yes" ? true : false

    if (content && image && image?.value.length === 0) {
      return (
        <TextContentBlock
          content={content}
          heading={heading?.value}
          subHeading={sub_heading?.value}
          eyebrowText={eyebrow_text?.value}
          buttons={buttons?.value}
          hasAltBackgroundColor={hasAltBackgroundColor}
          styleListsAsGrid={styleListsAsGrid}
        />
      )
    }
  }

  const getTextAndImageContentBlock = () => {
    const {
      content,
      image,
      eyebrow_text,
      content_block_type,
      buttons,
      style_lists_as_grid,
    } = blocks
    const styleListsAsGrid =
      style_lists_as_grid?.value[0]?.codename === "yes" ? true : false

    if (content && image && image?.value.length > 0) {
      return (
        <TextAndImageContentBlock
          content={content}
          heading={heading?.value}
          subHeading={sub_heading?.value}
          eyebrowText={eyebrow_text?.value}
          image={image?.value[0]}
          buttons={buttons?.value}
          hasAltBackgroundColor={hasAltBackgroundColor}
          mapRuns={mapRuns}
          contentBlockType={content_block_type}
          isAltBlock={isAltBlock}
          styleListsAsGrid={styleListsAsGrid}
        />
      )
    }
  }

  const getAffiliationSlider = () => {
    const { logo_slider_heading, logo_slider } = blocks
    if (logo_slider_heading) {
      return (
        <section className="fr-container">
          <AffiliationSlider
            isFromContentParser
            heading={logo_slider_heading.value}
            images={logo_slider?.value}
          />
        </section>
      )
    }
  }

  const getSingleImage = () => {
    const { single_image } = blocks
    if (single_image) {
      return (
        <div className="fr-container fr-single-image">
          <Image
            image={single_image.value[0]}
            alt={single_image.value[0]?.description ?? ""}
          />
        </div>
      )
    }
  }

  const getMultiContentBlocks = () => {
    const { content_block, multi_block_heading, layout_override_options } =
      blocks
    if (content_block) {
      return (
        <MultiContentBlocks
          heading={multi_block_heading?.value}
          subHeading={sub_heading?.value}
          contentBlock={content_block.modular_content}
          layoutOverrideOptions={
            layout_override_options?.value[0]?.codename || "default"
          }
          hasAltBackgroundColor={hasAltBackgroundColor}
        />
      )
    }
  }

  // TODO - Convert to TSX and add types to content block parser
  const getNavigationTiles = () => {
    const { navigation_tiles } = blocks
    if (navigation_tiles?.modular_content.length > 0) {
      return (
        <NavigationTiles
          navTiles={navigation_tiles.modular_content}
          hasAltBackgroundColor={hasAltBackgroundColor}
          heading={heading?.value}
          subHeading={sub_heading?.value}
          eyebrowText={eyebrow_text?.value}
        />
      )
    }
  }

  const getGallery = () => {
    const { gallery } = blocks
    if (gallery) {
      return (
        <section className="fr-container">
          <Gallery images={gallery.value} />
        </section>
      )
    }
  }

  const getServiceComparison = () => {
    const { services, disclaimer } = blocks
    if (services) {
      return (
        <ServiceComparison
          heading={heading?.value}
          subHeading={sub_heading?.value}
          services={services.modular_content}
          hasAltBackgroundColor={hasAltBackgroundColor}
          disclaimer={disclaimer?.value}
        />
      )
    }
  }

  const getFAQGroupBanner = () => {
    const { faq_group } = blocks
    if (!faq_group || faq_group?.modular_content?.length === 0) return

    return (
      <FAQGroupBanner
        heading={heading?.value}
        sub_heading={sub_heading?.value}
        hasAltBackgroundColor={hasAltBackgroundColor}
      >
        {faq_group.modular_content.map(({ elements, id }) => {
          const { faqs, is_faq_item_collapsable } = elements
          const faqsList = faqs?.modular_content
          const isCollapsable =
            is_faq_item_collapsable?.value[0]?.codename === "yes"

          if (faqsList?.length > 0) {
            return (
              <FAQGroupBlock
                key={id}
                faqs={faqsList}
                isCollapsable={isCollapsable}
              />
            )
          }
        })}
      </FAQGroupBanner>
    )
  }

  const getHtmlEmbed = () => {
    const { html } = blocks
    if (html) {
      return (
        <div className="html-container">
          <HtmlEmbed
            inlineHtml={html.value}
            hasAltBackgroundColor={hasAltBackgroundColor}
          />
        </div>
      )
    }
  }

  const getTabbedContent = () => {
    const { tabbed_content } = blocks
    if (tabbed_content && tabbed_content?.modular_content?.length > 0) {
      return (
        <TabbedContentBlockBanner
          heading={heading?.value}
          subHeading={sub_heading?.value}
          hasAltBackgroundColor={hasAltBackgroundColor}
          content={tabbed_content.modular_content}
        />
      )
    }
  }
  const getLegacyPestLibrary = () => {
    const { pages } = blocks
    if (pages && pages?.value.length > 0) {
      return (
        <SimpleTileList
          tiles={pages.value}
          hasAltBackgroundColor={legacyNavGridHasAltBackground}
        />
      )
    }
  }

  const getCoupons = () => {
    const { eyebrow_text, description, coupons } = blocks
    if (coupons && coupons?.modular_content?.length > 0) {
      return (
        <CouponBanner
          heading={heading?.value}
          subHeading={sub_heading?.value}
          eyebrowText={eyebrow_text?.value}
          description={description}
          coupons={coupons?.modular_content}
          hasAltBackgroundColor={hasAltBackgroundColor}
        />
      )
    }
  }

  return (
    <>
      {getSingleImage()}
      {getMultiContentBlocks()}
      {getNavigationTiles()}
      {getFAQGroupBanner()}
      {getServiceComparison()}
      {getGallery()}
      {getSingleService()}
      {getAffiliationSlider()}
      {getFormBuilder()}
      {getTextContentBlock()}
      {getTextAndImageContentBlock()}
      {getLegacyPestLibrary()}
      {getHighlights()}
      {getHtmlEmbed()}
      {getTabbedContent()}
      {getCoupons()}
    </>
  )
}

export const query = graphql`
  fragment ContentBlocksParserFragment on kontent_item_rich_text_element_value {
    modular_content {
      id
      ...HighlightFragment
      ...SingleImageFragment
      ...HtmlEmbedFragment
      ...MultiContentBlocksFragment
      ...NavigationTilesFragment
      ...FAQGroupBannerFragment
      ...ServiceComparisonFragment
      ...ServiceFragment
      ...GalleryFragment
      ...LogoSliderFragment
      ...FormFragment
      ...ContentBlockFragment
      ...TabbedContentBannerFragment
      ...HighlightGroupFragment
      ...CouponBannerFragment
      ...SimpleTileFragment
    }
  }
`
