import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import "./highlights.module.scss"
import { ImageBuilder } from "_types/AssetsImage"
import { Image } from "components/image/Image"
import { Content } from "components/content/Content"
import { RichTextContent } from "_types/RichText"

interface HighlightProps {
  image?: ImageBuilder
  caption?: RichTextContent
  title?: string
  leftSvg?: string
  rightSvg?: string
  counterStartValue?: number
  counterEndValue?: number
  inView?: boolean
  isAnimated?: boolean
  specialCharacter?: string
  baseClassName: string
}

export const Highlight = ({
  image,
  caption,
  title,
  leftSvg,
  rightSvg,
  specialCharacter,
  baseClassName,
  counterStartValue = 0,
  counterEndValue = 0,
  inView = false,
  isAnimated = false,
}: HighlightProps) => {
  const [counter, setCounter] = useState(() =>
    isAnimated ? counterStartValue : counterEndValue
  )

  const counterLength = specialCharacter
    ? String(counterEndValue).length + 1
    : String(counterEndValue).length

  const counterMinSizeCSSVariable = {} as { [key: string]: number }

  counterMinSizeCSSVariable[`--highlight-counter-size`] = counterLength

  const fadeInSpecialCharacterClassModifier =
    counter === counterEndValue
      ? `${baseClassName}__special-character--fade-in`
      : ""

  useEffect(() => {
    if (!isAnimated) return
    if (!counterEndValue) return

    let mounted = true

    const countdown = (counterStartValue: number, counterEndValue: number) => {
      const count = counterStartValue
      const increment = counterEndValue / 250
      if (count === counterEndValue || count > counterEndValue) {
        setCounter(counterEndValue)
        return
      }
      const updatedCounter = Math.ceil(count + increment)
      setCounter(updatedCounter)
    }

    if (!inView) return

    if (counter === counterEndValue) return

    setTimeout(() => {
      if (mounted) {
        countdown(counter, counterEndValue)
      }
    }, 3)

    return () => {
      mounted = false
    }
  }, [counter, counterEndValue, inView, counterStartValue, isAnimated])

  return (
    <div
      className={`${baseClassName}__child`}
      style={counterMinSizeCSSVariable}
      data-animated={isAnimated}
      data-counterstart={counterStartValue}
      data-counterend={counterEndValue}
    >
      {image && !leftSvg && !rightSvg && (
        <Image
          image={image}
          width={image.width}
          height={image.height}
          alt={image.description}
          className={`${baseClassName}__img`}
        />
      )}

      <div
        className={`${baseClassName}__row ${
          counterEndValue ? `${baseClassName}__row--counter` : ""
        }`}
      >
        {leftSvg && (
          <div
            className={`${baseClassName}__icon`}
            dangerouslySetInnerHTML={{ __html: leftSvg }}
            contentEditable="false"
          ></div>
        )}

        {counterEndValue && (
          <p className={`${baseClassName}__counter`}>
            {counter || counterEndValue}
            {specialCharacter && (
              <span
                className={`${baseClassName}__special-character ${fadeInSpecialCharacterClassModifier}`}
              >
                {specialCharacter}
              </span>
            )}
          </p>
        )}

        {rightSvg && (
          <div
            className={`${baseClassName}__icon`}
            dangerouslySetInnerHTML={{ __html: rightSvg }}
            contentEditable="false"
          ></div>
        )}
      </div>

      {title && <h4>{title}</h4>}
      {caption && (
        <div
          className={
            counterEndValue
              ? `${baseClassName}__text`
              : `${baseClassName}__caption`
          }
        >
          <Content content={caption} />
        </div>
      )}
    </div>
  )
}

export const query = graphql`
  fragment HighlightFragment on kontent_item_highlight {
    system {
      name
    }
    id
    elements {
      caption {
        value
        links {
          link_id
          url_slug
        }
      }
      image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      svg_icon {
        value
      }
      heading {
        value
      }
      counter_start_value {
        value
      }
      counter_end_value {
        value
      }
      special_character {
        value {
          name
        }
      }
      animated_counter {
        value {
          codename
        }
      }
    }
  }
`
