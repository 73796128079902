import React, { ReactNode, useRef } from "react"
import "./gallery-overlay.module.scss"
import { FocusOn } from "react-focus-on"

interface GalleryOverlayProps {
  isOverlay: boolean
  onClose: () => void
  children: ReactNode
}

export const GalleryOverlay = ({ isOverlay, onClose, children }: GalleryOverlayProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null)

  return (
    <div
      className={`fr-gallery-overlay ${
        isOverlay ? "" : "fr-gallery-overlay--closed"
      }`}
    >
      {isOverlay && (
        <FocusOn
          shards={[buttonRef]}
          onClickOutside={onClose}
          onEscapeKey={onClose}
        >
          {children}
        </FocusOn>
      )}
    
      <button
        className="fr-gallery-overlay__btn fr-gallery-overlay__btn--exit"
        onClick={onClose}
        ref={buttonRef}
      >
        X
      </button>
    </div>
  )
}
