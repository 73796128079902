import React from "react"
import { Link, graphql } from "gatsby"
import { truncString } from "assets/helper/truncString"
import "./navigation-tile.module.scss"
import { Image } from "components/image/Image"
import { useLayout } from "contexts/components/LayoutContext"
import { NavigationTileElements } from "./navigation-tile-type"

interface NavigationTileProps {
  tiles: NavigationTileElements
  showDescription?: boolean
  isDense?: boolean
}

export const NavigationTile = ({
  tiles,
  showDescription = false,
  isDense = false,
}: NavigationTileProps) => {
  const {
    background_image,
    cta,
    heading,
    internal_link,
    description,
    svg_icon,
  } = tiles

  const slug = internal_link?.value[0]?.elements?.slug?.value
  const { linkedPagesUrlPathCodex } = useLayout()
  const path = linkedPagesUrlPathCodex[slug] || "404"
  const navSlug = path.startsWith("/") ? path : `/${path}`
  const isDenseClassName = isDense ? "nav-tile--dense" : ""

  return (
    <Link to={navSlug} className={`nav-tile ${isDenseClassName}`}>
      <Image
        image={background_image.value[0]}
        className={`nav-tile__image`}
        alt={background_image.value[0].description}
        objectFit="cover"
      />

      <div className={`nav-tile__content`}>
        {heading && (
          <h3
            className={`nav-tile__title u-text--color-white u-text--font-shadow`}
          >
            {truncString(heading.value, 155)}
          </h3>
        )}
        <div className="nav-tile__action-container">
          <div className="nav-tile__action">
            {description?.value && (
              <p
                className={`nav-tile__description u-text--align-left u-text--color-white u-text--font-shadow`}
              >
                {truncString(description.value, 155)}
              </p>
            )}
            <div className={`nav-tile__cta-wrapper`}>
              <div className={`nav-tile__cta`}>
                <span> {cta.value} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export const query = graphql`
  fragment NavigationTileFragment on kontent_item_navigation_tile {
    id
    elements {
      heading {
        value
      }
      description {
        value
      }
      cta {
        value
      }
      background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      svg_icon {
        value
      }
      internal_link {
        value {
          ... on kontent_item_page {
            id
            elements {
              slug {
                value
              }
            }
          }
        }
      }
    }
  }
`
