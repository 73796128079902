import React, { useCallback, useState } from "react"
import { Image } from "../image/Image"
import { graphql } from "gatsby"
import { GallerySlider } from "./GallerySlider"
import { GalleryOverlay } from "./GalleryOverlay"
import { SingleGalleryImage } from "./gallery-types"
import "./gallery.module.scss"

interface GalleryProps {
  images: SingleGalleryImage[]
}

export const Gallery = ({ images }: GalleryProps) => {
  const [isOverlay, setIsOverlay] = useState(false)
  const [firstSlideIndex, setFirstSlideIndex] = useState(0)

  const handleCloseOverlay = useCallback(() => {
    setIsOverlay(false)
  }, [])

  const openOverlay = (index: number) => {
    setFirstSlideIndex(index)
    setIsOverlay(true)
  }

  return (
    <div className="gallery">
      {images.map(({ elements, id }, index) => {
        const { single_image } = elements
        return (
          <div
            key={id}
            onClick={() => openOverlay(index)}
          >
            <Image
              image={single_image.value[0]}
              alt={single_image.value[0]?.description ?? ""}
              className="gallery__image"
            />
          </div>
        )
      })}
      <GalleryOverlay isOverlay={isOverlay} onClose={handleCloseOverlay}>
        <GallerySlider images={images} firstSlideIndex={firstSlideIndex} />
      </GalleryOverlay>
    </div>
  )
}

export const query = graphql`
  fragment GalleryFragment on kontent_item_image_gallery {
    id
    system{
      type
    }
    elements {
      gallery {
        value {
          ...SingleImageFragment
        }
      }
    }
  }
`
