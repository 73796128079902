import React from "react"
import "./multi-content-block.module.scss"
import { Content } from "../content/Content"
import { Image } from "../image/Image"
import { ButtonParser } from "components/button/ButtonParser"
import { IContentBlock } from "components/content-blocks/content-blocks-types"

interface MultiContentBlockProps {
  contentBlock: IContentBlock
}

export const MultiContentBlock = ({ contentBlock }: MultiContentBlockProps) => {
  const { heading, sub_heading, content, image, buttons } = contentBlock

  return (
    <div className="MCB">
      {image && image?.value.length > 0 && (
        <Image
          image={image.value[0]}
          alt={image.value[0].description}
          className="MCB__image"
        />
      )}

      {heading?.value && <h4 className="MCB__heading">{heading.value}</h4>}
      {sub_heading?.value && (
        <h5 className="MCB__sub-heading">{sub_heading.value}</h5>
      )}
      {content && content?.value !== "<p><br></p>" && (
        <div className="MCB__content">
          <Content content={content} />
        </div>
      )}
      {buttons && buttons?.value?.length > 0 && (
        <div className="MCB__cta-container">
          <ButtonParser
            buttons={buttons.value}
            extraClassNames="MCB__cta"
            primaryColorVariant="solid-secondary"
            secondaryColorVariant="outline-secondary"
            hasFallbackColorVariant
            fallbackColorVariant="outline-secondary"
          />
        </div>
      )}
    </div>
  )
}
