import React from "react"
import { graphql } from "gatsby"
import { MultiContentBlock } from "./MultiContentBlock"
import "./multi-content-blocks.module.scss"
import { SingleContentBlock } from "./multi-content-block"
import { HtmlEmbed } from "components/html-embed/HtmlEmbed"

interface MultiContentBlocksProps {
  contentBlock: SingleContentBlock[]
  heading?: string
  subHeading?: string
  layoutOverrideOptions?: string
  hasAltBackgroundColor: boolean
}

export const MultiContentBlocks = ({
  heading,
  subHeading,
  contentBlock,
  layoutOverrideOptions = "",
  hasAltBackgroundColor,
}: MultiContentBlocksProps) => {
  const getMultiContentBlockClassNameByBlockCount = (
    input: number | string
  ) => {
    switch (input) {
      case 2:
      case "n2_columns":
        return "multi-content-block-wrapper-2-blocks"
      case 4:
      case "n4_columns":
        return "multi-content-block-wrapper-4-blocks"
      case 5:
        return "multi-content-block-wrapper-5-blocks"
      case 7:
        return "multi-content-block-wrapper-7-blocks"
      default:
        return "multi-content-block-wrapper-3-blocks"
    }
  }
  const contentBlocks = contentBlock?.filter(
    component => component?.system?.type === "content_block"
  )

  const embedHtml = contentBlock?.filter(
    component => component?.system?.type === "html_embed"
  )

  const alternateBackground = hasAltBackgroundColor
    ? " alternate-background-color"
    : ""

  const styleSubHeadingAsIntroParagraph =
    !!subHeading && subHeading.length > 150

  const blockCount = contentBlocks.length
  const multiContentBlockClassName = layoutOverrideOptions
    ? getMultiContentBlockClassNameByBlockCount(layoutOverrideOptions)
    : getMultiContentBlockClassNameByBlockCount(blockCount)

  return (
    <section
      className={`multi-content-blocks u-content-block-padding ${alternateBackground}`}
    >
      <div
        className={`fr-container ${
          blockCount >= 3 ? "fr-container--large" : ""
        }`}
      >
        {heading && (
          <div className="text-content-block__title">
            <h2>{heading}</h2>
            {subHeading && <h3>{subHeading}</h3>}
          </div>
        )}
        <div className={multiContentBlockClassName}>
          {contentBlocks.map(({ id, elements }) => {
            if (!elements) return
            return <MultiContentBlock key={id} contentBlock={elements} />
          })}
          {embedHtml &&
            embedHtml.map(({ elements }) => {
              return (
                <div className="html-container">
                  <div className="embed-content">
                    <HtmlEmbed
                      inlineHtml={elements?.html.value}
                      hasAltBackgroundColor={hasAltBackgroundColor}
                    />
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment MultiContentBlocksFragment on kontent_item_multi_content_block {
    id
    system {
      type
    }
    elements {
      multi_block_heading {
        value
      }
      multi_content_background_image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      sub_heading {
        value
      }
      layout_override_options {
        value {
          codename
        }
      }
      alt_background_color {
        value {
          codename
        }
      }
      content_block {
        modular_content {
          ...ContentBlockFragment
          ...HtmlEmbedFragment
        }
      }
      custom_id {
        value
      }
    }
  }
`
