import React, { ReactNode, useState, useCallback } from 'react';
import { TabbedContainerContext } from '../../contexts/components/TabbedContainerContext';

type BaseItem = {
  id?: string
}

interface TabbedContainerProps<T> {
  children: ReactNode
  allContent: T[]
  firstActiveTabIndex?: number
}

export const TabbedContainer = <T extends BaseItem>({
  children, 
  allContent,
  firstActiveTabIndex = 0
}: TabbedContainerProps<T>) => {
  const [activeTabIndex, setActiveTabIndex] = useState(firstActiveTabIndex)

  const handleChangeActiveTab = useCallback((index: number) => {
    setActiveTabIndex(index)
  }, [])

  return (
    <TabbedContainerContext.Provider value={{
      activeTabIndex,
      allContent,
      activeItem: allContent[activeTabIndex],
      handleChangeActiveTab
    }}>
      <div className='fr-tabbed-container'>
        {children}
      </div>
    </TabbedContainerContext.Provider>
  )
}