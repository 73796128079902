import React from "react"
import { graphql } from "gatsby"
import { Content } from "components/content/Content"
import { Button } from "components/button"
import { RichTextContent } from "_types/RichText"
import { ButtonElements } from "_types/Button"
import { useLogos } from "hooks/queries/settings/useLogos"
import { Image } from "components/image/Image"
import { ExternalLink } from "components/external-link/ExternalLink"

interface CouponProps {
  couponValue?: string
  subHeading?: string
  description?: RichTextContent
  button: ButtonElements
  disclaimer?: string
  endDate?: string
  hideLogo?: boolean
}

export const Coupon = ({
  couponValue,
  subHeading,
  description,
  button,
  disclaimer,
  endDate,
  hideLogo = false,
}: CouponProps) => {
  const { button_text, external_link, linked_page, anchor_reference } = button

  const slug = linked_page
    ? linked_page.value[0].elements.slug.value!
    : external_link?.value!
  const anchorReference = anchor_reference?.value[0]?.name ? anchor_reference.value[0].name : 'form-banner'
  const { headerLogo } = useLogos()
  return (
    <div className="coupon">
      <div className="coupon__header">
        {couponValue && <h2 className="coupon__heading">{couponValue}</h2>}
        {subHeading && <h3 className="coupon__sub-heading">{subHeading}</h3>}
        {description && <Content content={description} />}
      </div>
      <div className="coupon__footer">
        {anchorReference ? (
          <Button
            to={`#${anchorReference}`}
            variant="solid-accent"
            extraClassNames="coupon__button"
          >
            {button_text?.value}
          </Button>
        ) : (
          <>
            {linked_page ? (
              <Button
                to={slug?.startsWith("/") ? slug : `/${slug}`}
                variant="solid-accent"
                extraClassNames="coupon__button"
              >
                {button_text?.value}
              </Button>
            ) : (
              <ExternalLink
                href={external_link?.value}
                className="button--solid-primary coupon__button"
              >
                {button_text?.value}
              </ExternalLink>
            )}
          </>
        )}

        {!hideLogo && <Image image={headerLogo} className="coupon__logo" />}
        {endDate && <p className="coupon__end-date">Offer Ends: {endDate}</p>}
        {disclaimer && <p className="coupon__disclaimer">*{disclaimer}</p>}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment CouponFragment on kontent_item_coupon {
    system {
      type
    }
    id
    elements {
      coupon_value {
        value
      }
      sub_heading {
        value
      }
      description {
        value
      }
      hide_logo {
        value {
          codename
        }
      }
      button {
        value {
          ...ButtonFragment
          ...AnchorLinkFragment
        }
      }
      offer_end_date {
        value
      }
      disclaimer {
        value
      }
    }
  }
`
