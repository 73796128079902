import React from "react"
import { Content } from "../content/Content"
import "./content-block.module.scss"
import { RichTextContent } from "_types/RichText"
import { ButtonBuilder } from "_types/Button"
import { ButtonParser } from "components/button/ButtonParser"

interface TextContentBlockProps {
  content?: RichTextContent
  heading?: string
  subHeading?: string
  eyebrowText?: string
  hasAltBackgroundColor?: boolean
  buttons?: ButtonBuilder[]
  styleListsAsGrid?: boolean
}

export const TextContentBlock = ({
  content,
  heading,
  eyebrowText,
  subHeading,
  hasAltBackgroundColor,
  buttons,
  styleListsAsGrid = false,
}: TextContentBlockProps) => {
  const contentNotEmpty = content?.value === "<p><br></p>" ? false : true

  const alternateBackground = hasAltBackgroundColor
    ? "alternate-background-color"
    : ""
  const styleSubHeadingAsIntroParagraph =
    !!subHeading && subHeading.length > 150
  return (
    <section
      className={`text-content-block u-content-block-padding ${alternateBackground} u-clearfix`}
    >
      <div className="fr-container">
        {(eyebrowText || heading || subHeading) && (
          <div className={`text-content-block`}>
            {eyebrowText && <p className={`eyebrow-text`}>{eyebrowText}</p>}
            {heading && <h2>{heading}</h2>}
            {subHeading && (
              <>
                {styleSubHeadingAsIntroParagraph ? (
                  <p>{subHeading}</p>
                ) : (
                  <h3>{subHeading}</h3>
                )}
              </>
            )}
          </div>
        )}
        {content && contentNotEmpty && (
          <div className="text-content-block__content">
            <Content content={content} styleListsAsGrid={styleListsAsGrid} />
          </div>
        )}
        {buttons && buttons.length > 0 && (
          <div className="content-block-buttons">
            <ButtonParser buttons={buttons} />
          </div>
        )}
      </div>
    </section>
  )
}
