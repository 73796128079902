import React from "react"
import { PriceTierElements } from "./price-tier-types"

interface PriceTierProps {
  priceTier: PriceTierElements
  priceTerm: string
}

export const PriceTier = ({ priceTier, priceTerm }: PriceTierProps) => {
  const { price, initial_fee } = priceTier

  const getPriceArray = (price: string) => {
    let newPrice = price.split(".")

    return (
      <p className="price-tier__price">
        <sup className="price-tier__price--super-script">$</sup>
        {newPrice[0]}
        <sup className="price-tier__price--super-script">.{newPrice[1]}</sup>
      </p>
    )
  }
  return (
    <div className="price-tier">
      <div className="price-tier__initial">
        <p>Initial Service</p>
        {getPriceArray(initial_fee.value)}
      </div>
      <div className="price-tier__recurring">
        <p>
          {`${priceTerm.charAt(0).toUpperCase()}${priceTerm.substring(1)} `}
          Price
        </p>
        {getPriceArray(price.value)}
      </div>
    </div>
  )
}
