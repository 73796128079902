import React from "react"
import { graphql } from "gatsby"
import { NavigationTile } from "./NavigationTile"
import { SingleNagivationTile } from "./navigation-tile-type"

interface NavigationTilesProps {
  navTiles: SingleNagivationTile[]
  hasAltBackgroundColor: boolean
  heading?: string
  subHeading?: string
  eyebrowText?: string
}

export const NavigationTiles = ({
  heading,
  eyebrowText,
  subHeading,
  navTiles,
  hasAltBackgroundColor,
}: NavigationTilesProps) => {
  // Navigation tiles are a specific type of content block that is returned by Content blocks.
  const alternateBackground = hasAltBackgroundColor
    ? "alternate-background-color"
    : ""

  return (
    <section
      className={`nav-tile-container u-content-block-padding u-clearfix ${alternateBackground}`}
    >
      <div className={`fr-container`}>
        {heading && (
          <div className={`u-container-header`}>
            <div className=" text-content-block__title">
              {eyebrowText && <p className={`eyebrow-text`}>{eyebrowText}</p>}
              <h2>{heading}</h2>
            </div>
            {subHeading && <p>{subHeading}</p>}
          </div>
        )}
        <div className={`nav-tile__wrapper`}>
          {navTiles.map(({ id, elements }) => {
            if (!elements) return
            return (
              <NavigationTile
                key={id}
                tiles={elements}
                isDense={navTiles.length > 4}
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment NavigationTilesFragment on kontent_item_navigation_block {
    id
    system {
      type
    }
    elements {
      heading {
        value
      }
      eyebrow_text {
        value
      }
      sub_heading {
        value
      }
      alt_background_color {
        value {
          codename
        }
      }
      navigation_tiles {
        modular_content {
          ...NavigationTileFragment
        }
      }
    }
  }
`
