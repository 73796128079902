import React from 'react'
import { TextAndImageContentBlock } from 'components/content-blocks/TextAndImageContentBlock'
import { useTabbedContainer } from 'contexts/components/TabbedContainerContext'
import { TabContentBlockItem } from './tabbed-content-block-types'
import { SingleService } from 'components/service-comparison/SingleService'

export const TabbedContent = () => {
  const { activeItem } = useTabbedContainer<TabContentBlockItem>()
  
  const activeItemElements = activeItem?.elements?.content_block?.modular_content[0]?.elements

  if (!activeItemElements) return null

  if (activeItemElements.hasOwnProperty("service_features")) {
    const { service_features, pests_covered, price_term, button, price_tiers, image } = activeItemElements
    return (
      <SingleService 
        serviceFeatures={service_features}
        pestsCovered={pests_covered}
        priceTerm={price_term?.value[0].codename}
        ctaButton={button?.value[0]}
        priceTiers={price_tiers?.value[0]}
        serviceCount={1}
        featuredImage={image?.value[0]}
      />
    )
  }
  
  const { content, buttons, image, style_lists_as_grid } = activeItemElements

  return (
    <TextAndImageContentBlock
      content={content}
      buttons={buttons?.value}
      image={image?.value[0]}
      styleListsAsGrid={style_lists_as_grid?.value[0]?.codename === "yes"}
      hasBlockPadding={false}
      hasContainerClassName={false}
    />
  )
}