import React, { createContext, useContext } from 'react'

type BaseItem = {
  id?: string
}

type TabbedContainerContextData<T extends BaseItem> = {
  activeTabIndex: number
  handleChangeActiveTab: (arrayIndex: number) => void
  allContent: T[]
  activeItem: T
}

export const TabbedContainerContext = createContext<TabbedContainerContextData<BaseItem>>({} as TabbedContainerContextData<BaseItem>)

export function useTabbedContainer<T extends BaseItem>() {
  const context = useContext<TabbedContainerContextData<T>>(
    (TabbedContainerContext as unknown) as React.Context<TabbedContainerContextData<T>>
  )

  if (!context) {
    throw new Error("useTabbedContainer must be used within TabbedContainerContext")
  }

  return context
}