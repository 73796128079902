import React, { MouseEventHandler } from 'react';
import { GoChevronLeft, GoChevronRight } from 'react-icons/go'

type SlickArrow = {
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export function SampleNextArrow(props: SlickArrow) {
  const { onClick } = props;
  return (
    <button
      type="button"
      data-role="none"
      className="slick-arrow fr-gallery-slider__slick-arrow fr-gallery-slider__slick-arrow--next"
      onClick={onClick}
    >
      <GoChevronRight size={40} />
    </button>
  );
}

export function SamplePrevArrow(props: SlickArrow) {
  const { onClick } = props;
  return (
    <button
      type="button"
      data-role="none"
      className="slick-arrow fr-gallery-slider__slick-arrow fr-gallery-slider__slick-arrow--prev"
      onClick={onClick}
    >
      <GoChevronLeft size={40} />
    </button>
  );
}