import React from "react"
import "./service.module.scss"
import { graphql } from "gatsby"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { useGeneralSettings } from "hooks/useGeneralSettings"
import { ServiceElements } from "./service-types"
import { ButtonParser } from "components/button/ButtonParser"

interface ServiceProps {
  serviceInfo: ServiceElements
  index: number
  serviceCount?: number
}

export const Service = ({ serviceInfo, index, serviceCount = 0 }: ServiceProps) => {
  const {
    name,
    service_features,
    price,
    button,
  } = serviceInfo

  const { trackingNumbers, usePhoneNumberNotTrackingNumber } = useGeneralSettings()
  const trackingPhoneNumber = trackingNumbers.length > 0 ? trackingNumbers[0]?.trackingNumber : ""
  const singlePhoneNumber = trackingNumbers.length > 0 ? trackingNumbers[0]?.phoneNumber : ""

  const contactNumber = usePhoneNumberNotTrackingNumber ? singlePhoneNumber : trackingPhoneNumber
  return (
    <div
      className={
        serviceCount >= 3 && index === 1
          ? `service service--most-popular`
          : `service`
      }
    >
      {name?.value && (
        <div className="service__header">
          <h3>{name.value}</h3>
        </div>
      )}
      {(service_features?.value && service_features?.value !== "<p><br></p>") && (
        <div className="service__body">
          <RichTextElement value={service_features.value} />
        </div>
      )}
      <div
        className={
          index === 1
            ? `service__footer service__footer--primary`
            : `service__footer `
        }
      >
        {price?.value !== "" && <p>{price?.value}</p>}

        {button && button.value?.length > 0 && (
          <div className="content-block-buttons">
            <ButtonParser
              buttons={button.value}
              hasFallbackColorVariant 
              fallbackColorVariant="outline-ghost" 
            />
          </div>
        )}

        {contactNumber && (
          <div className="service__alternate-cta">
            Or Call:{" "}
            <a href={`tel:${contactNumber}`} className="service__phone">
              {contactNumber}
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment ServiceFragment on kontent_item_service {
    id
    system{
      type
    }
    elements {
      name {
        value
      }
      service_features {
        value
        links {
          link_id
          url_slug
        }
      }
      icon {
        value {
          ...KenticoAssetElementFragment
        }
      }
      image_gallery {
        modular_content {
          ...GalleryFragment
        }
      }
      pests_covered {
        value
        links {
          link_id
          url_slug
        }
      }
      price {
        value
      }
      price_term {
        value {
          codename
        }
      }
      pricing_disclaimer {
        value
      }
      button {
        value {
          ...AnchorLinkFragment
          ...ButtonFragment
        }
      }
      image {
        value {
          ...KenticoAssetElementFragment
        }
      }
      price_tiers {
        value {
          ... on kontent_item_service_price_tier {
            id
            elements {
              ending_footage {
                value
              }
              footage_type {
                value {
                  codename
                }
              }
              initial_fee {
                value
              }
              price {
                value
              }
              starting_footage {
                value
              }
            }
          }
        }
      }
    }
  }
`
