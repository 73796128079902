import React from "react"
import { graphql } from "gatsby"
import InnerHTML from "dangerously-set-html-content"
import "./html-embed.module.scss"

interface HtmlEmbedProps {
  inlineHtml: string
  hasAltBackgroundColor: boolean
}

export const HtmlEmbed = ({
  inlineHtml,
  hasAltBackgroundColor,
}: HtmlEmbedProps) => {
  const alternateBackground = hasAltBackgroundColor
    ? "alternate-background-color u-content-block-padding"
    : "u-content-block-padding"

  const altBgModifierForBCPO = hasAltBackgroundColor
    ? "fr-html-embed--alt-bg"
    : ""

  return (
    <div
      className={`fr-html-embed ${altBgModifierForBCPO} ${alternateBackground}`}
    >
      <InnerHTML contentEditable="false" html={inlineHtml}></InnerHTML>
    </div>
  )
}

export const query = graphql`
  fragment HtmlEmbedFragment on kontent_item_html_embed {
    system {
      type
    }
    id
    elements {
      html {
        value
      }
      alt_background_color {
        value {
          codename
        }
      }
    }
  }
`
