import React, { ComponentType } from "react"
import { Text } from "_types/Text"
import { useTabbedContainer } from "contexts/components/TabbedContainerContext"
import { IconBaseProps } from 'react-icons'

type Tab = {
  id: string
  elements: {
    tab_title: Text
    svg_icon?: Text
  }
}

interface TabsProps {
  tabbedContent?: any
  selectedServiceIndex?: number
  setSelectedService?: (selectedServiceId: number) => void
  className?: string
  showTabTitleIcon?: boolean
  showSliderOnMobile?: boolean
  previousIcon?: ComponentType<IconBaseProps>
  nextIcon?: ComponentType<IconBaseProps>
  hasSliderIndicatorBar?: boolean
  sliderBarDirection?: "horizontal" | "vertical"
}

export const Tabs = ({
  previousIcon: PreviousIcon,
  nextIcon: NextIcon,
  className = "fr-tabs-controller",
  showTabTitleIcon = false,
  showSliderOnMobile = true,
  hasSliderIndicatorBar = false,
  sliderBarDirection = "horizontal"
}: TabsProps) => {
  const { activeTabIndex, handleChangeActiveTab, allContent } = useTabbedContainer<Tab>()
  
  const handleNextContent = (activeTabIndex: number) => {
    const max = allContent.length - 1
    const index = activeTabIndex < max ? activeTabIndex + 1 : 0
    handleChangeActiveTab(index)
  }

  const handlePreviousContent = (activeTabIndex: number) => {
    const max = allContent.length - 1
    const index = activeTabIndex > 0 ? activeTabIndex - 1 : max
    handleChangeActiveTab(index)
  }
  
  const sliderIndicatorStyles = sliderBarDirection === "vertical" 
    ? { transform: `translateY(calc(100%/${allContent.length}*${activeTabIndex}))` }
    : { width: `calc(100%/${allContent.length})`, transform: `translateX(calc(100%*${activeTabIndex}))` }

  return (
    <div className={className}>
      {showSliderOnMobile && (
        <div className={`${className}__slider-wrapper`}>
          {PreviousIcon && (
            <button 
              className={`${className}__slider-button`} 
              onClick={() => handlePreviousContent(activeTabIndex)}>
                <PreviousIcon />
            </button>
          )}
          <div className={`${className}__slider-tab-title`} >{allContent[activeTabIndex]?.elements.tab_title.value}</div>
          {NextIcon && (
            <button 
              className={`${className}__slider-button`} 
              onClick={() => handleNextContent(activeTabIndex)}>
                <NextIcon />
            </button>
          )}
        </div>
      )}

      <div className={`${className}__tabs-wrapper`}>
        {allContent.map(({id, elements}, index) => {
            const tabTitle = elements.tab_title.value
            const inlineSVGIcon = elements?.svg_icon?.value
            return (
              <button
                onClick={() => handleChangeActiveTab(index)}
                key={id}
                className={`${className}__tab${activeTabIndex === index ? ` ${className}__tab--active` : ""}`}
              >
                {showTabTitleIcon && inlineSVGIcon && (
                  <span
                    className={`${className}__tab-icon`}
                    contentEditable="false"
                    dangerouslySetInnerHTML={{ __html: inlineSVGIcon }}
                  />
                )}
                {tabTitle}
              </button>
            )
        })}
      </div>
        {hasSliderIndicatorBar && (
          <div className={`${className}__tabs-bottom-slider`} style={sliderIndicatorStyles}>
            <div className={`${className}__tabs-indicator`}></div>
          </div>
        )}
    </div>
  )
} 