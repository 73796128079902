import React, { createContext, useContext } from 'react'

type AccordionContextData = {
  activePanel: string
  handlePanelClick: (id: string) => void
  isFAQ: boolean
}

type AccordionItemContextData = {
  id: string
}

export const AccordionContext = createContext({} as AccordionContextData)
export const AccordionItemContext = createContext({} as AccordionItemContextData)

export function useAccordion() {
  const context = useContext(AccordionContext)

  if (!context) {
    throw new Error("useAccordion must be used within AccordionContext")
  }

  return context
}

export function useAccordionItem() {
  const context = useContext(AccordionItemContext)

  if (!context) {
    throw new Error("useAccordionItem must be used within AccordionItemContext")
  }

  return context
}