import React from "react"
import { graphql } from "gatsby"
import { Service } from "./Service"
import { IService } from "./service-types"
import "./service.module.scss"

interface ServiceComparisonProps {
  heading?: string
  subHeading?: string
  hasAltBackgroundColor: boolean
  services: IService[]
  disclaimer?: string
}

export const ServiceComparison = ({
  heading,
  subHeading,
  hasAltBackgroundColor,
  services,
  disclaimer,
}: ServiceComparisonProps) => {
  const isLargeContainer = services.length > 2 ? true : false
  const styleSubHeadingAsIntroParagraph =
    !!subHeading && subHeading.length > 150
  return (
    <section
      className={
        hasAltBackgroundColor
          ? "service-comparison--alt u-content-block-padding"
          : "service-comparison u-content-block-padding"
      }
    >
      <div
        className={
          isLargeContainer ? `fr-container fr-container--large` : `fr-container`
        }
      >
        {heading && (
          <div className="text-content-block__title">
            <h2>{heading}</h2>
            {subHeading && (
              <>
                {styleSubHeadingAsIntroParagraph ? (
                  <p>{subHeading}</p>
                ) : (
                  <h3>{subHeading}</h3>
                )}
              </>
            )}
          </div>
        )}

        <div
          className={
            services.length >= 3
              ? `service-comparison__grid service-comparison__grid--most-popular`
              : "service-comparison__grid"
          }
        >
          {services.map(({ elements, id }, i) => (
            <Service
              key={id}
              serviceInfo={elements}
              index={i}
              serviceCount={services.length}
            />
          ))}
        </div>
        {disclaimer && (
          <p className="service-comparison__disclaimer">{disclaimer}</p>
        )}
      </div>
    </section>
  )
}

export const query = graphql`
  fragment ServiceComparisonFragment on kontent_item_service_comparison {
    id
    system {
      type
    }
    elements {
      heading {
        value
      }
      sub_heading {
        value
      }
      alt_background_color {
        value {
          codename
        }
      }
      services {
        modular_content {
          ...ServiceFragment
        }
      }
      disclaimer {
        value
      }
      custom_id {
        value
      }
    }
  }
`
