import React from "react"
import { ContentBlockParser } from "./ContentBlockParser"

// TODO - Add type to Content elements once we have all the children components typed
type Content = {
  id: string
  elements: Record<string, any>
}

interface ContentBlocksProps {
  content: Content[]
  legacyNavGridHasAltBackground?: boolean
  hasAlternatingContentBlocks?: boolean
}

export const ContentBlocks = ({
  content,
  hasAlternatingContentBlocks = true,
  legacyNavGridHasAltBackground = false,
}: ContentBlocksProps) => {
  let mapRunsAlt = 0 // keeps track of how many content blocks that need to be have alternating flex direction
  let serviceCount = 0
  const filteredContentBlocks = content.filter(
    block => Object.keys(block).length !== 0
  )
  const numBlocks = filteredContentBlocks.length

  let isFaqPage = false
  filteredContentBlocks.map(({ system }) => {
    if (system?.type === 'faq_group_banner') {
      isFaqPage = true
    }
  })

  //ContentBlocks is a stepping stone element that does the work of splitting up each type of content block possible.
  if (isFaqPage) {
    return (
      <article itemScope itemType="https://schema.org/FAQPage">
        {filteredContentBlocks.map(({ id, elements }, i) => {
          if (!elements) return
  
          let isAltBlock =
            hasAlternatingContentBlocks &&
            elements?.content?.value.toString() !== "<p><br></p>" &&
            elements?.image?.value.length > 0 &&
            elements?.content_block_type?.value.length > 0 &&
            elements?.content_block_type?.value[0]?.codename !==
              "text_wraps_image"
              ? true
              : false
          if (isAltBlock) mapRunsAlt++
  
          if (elements.service_features) serviceCount++
          return (
            <React.Fragment key={id}>
              <ContentBlockParser
                  blocks={elements}
                  position={i}
                  blockCount={numBlocks}
                  mapRuns={mapRunsAlt}
                  isAltBlock={isAltBlock}
                  serviceCount={serviceCount}
                  legacyNavGridHasAltBackground={legacyNavGridHasAltBackground}
                />
            </React.Fragment>
          )
        })}
      </article>
    )
  } else {
    return (
      <article>
        {filteredContentBlocks.map(({ id, elements }, i) => {
          if (!elements) return
  
          let isAltBlock =
            hasAlternatingContentBlocks &&
            elements?.content?.value.toString() !== "<p><br></p>" &&
            elements?.image?.value.length > 0 &&
            elements?.content_block_type?.value.length > 0 &&
            elements?.content_block_type?.value[0]?.codename !==
              "text_wraps_image"
              ? true
              : false
          if (isAltBlock) mapRunsAlt++
  
          if (elements.service_features) serviceCount++
          return (
            <React.Fragment key={id}>
              <ContentBlockParser
                  blocks={elements}
                  position={i}
                  blockCount={numBlocks}
                  mapRuns={mapRunsAlt}
                  isAltBlock={isAltBlock}
                  serviceCount={serviceCount}
                  legacyNavGridHasAltBackground={legacyNavGridHasAltBackground}
                />
            </React.Fragment>
          )
        })}
      </article>
    )
  }
}
