import React, { ReactElement } from "react";
import { AccordionItemContext, useAccordion } from "contexts/components/AccordionContext";
import "./accordion.module.scss"

interface AccordionItemProps {
  children: ReactElement[]
  id: string
}

export const AccordionItem = ({id, children}: AccordionItemProps) => {
  const { isFAQ } = useAccordion()

  const faqProps = isFAQ ? {
    itemScope: true,
    itemProp: "mainEntity",
    itemType: "https://schema.org/Question"
  } : {}

  return (
    <AccordionItemContext.Provider value={{
      id
    }}>
      <div {...faqProps} className='fr-accordion__item'>
        {children}
      </div>
    </AccordionItemContext.Provider>
  )
}