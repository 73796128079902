import React from 'react'
import { useAccordion, useAccordionItem } from 'contexts/components/AccordionContext'
import { GoChevronDown, GoChevronUp } from "react-icons/go"
import "./accordion.module.scss"

interface AccordionToggleProps {
  children: string
}

export const AccordionToggle = ({children}: AccordionToggleProps) => {
  const { handlePanelClick, isFAQ, activePanel } = useAccordion()
  const { id } = useAccordionItem()

  const isButtonActive = activePanel === id ? " fr-accordion__btn-toggle--active" : ""

  const faqName = isFAQ ? {
    itemProp: "name"
  } : {}

  return (
    <button 
      type='button' 
      className={`fr-accordion__btn-toggle${isButtonActive}`}
      onClick={() => handlePanelClick(id)}
    >
      <h3 {...faqName}>{children}</h3>
      {(activePanel === id) ? (
        <GoChevronUp size={32} style={{marginLeft: "0.5rem"}} />
      ) : (
        <GoChevronDown size={32} style={{marginLeft: "0.5rem"}} />
      )}
    </button>
  )
}