import React, { KeyboardEvent, useRef, useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Image } from "../image/Image"
import { SampleNextArrow, SamplePrevArrow } from "./SliderArrow"
import { SingleGalleryImage } from "./gallery-types"
import "./gallery-slider.module.scss"

interface GallerySliderProps {
  images: SingleGalleryImage[]
  firstSlideIndex: number
}

export const GallerySlider = ({ images, firstSlideIndex }: GallerySliderProps) => {
  const slideIndex = useRef(firstSlideIndex)
  const [nav1, setNav1] = useState<Slider>()
  const [nav2, setNav2] = useState<Slider>()
  
  const handleNextSlide = () => {
    if (nav1) {
      nav1.slickNext()
    }
  }

  const handlePrevSlide = () => {
    if (nav1) {
      nav1.slickPrev()
    }
  }

  const keyAction = (e: KeyboardEvent) => {
    e.keyCode === 39 && handleNextSlide()
    e.keyCode === 37 && handlePrevSlide()
  }
  const setSlideIndex = (index: number) => {
    if (slideIndex) {
      slideIndex.current = index
    }
  }
  const settings = {
    className: "fr-gallery-slider__body",
    dots: false,
    initialSlide: slideIndex.current,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    swipeToSlide: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    beforeChange: (current: number, next: any) => {
      if (next !== current) {
        setSlideIndex(next)
      }
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
        }
      }
    ]
  }

  const mobileSliderSettings = {
    className: "fr-gallery-slider__body--mobile",
    dots: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    swipeToSlide: true,
    initialSlide: slideIndex.current,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    beforeChange: (current: number, next: any) => {
      if (next !== current) {
        setSlideIndex(next)
      }
    },
  }
  

  return (
    <div className="fr-gallery-slider" onKeyDown={keyAction}>
      <div className="fr-gallery-slider__laptop-controller">
        <Slider asNavFor={nav1} {...settings} ref={slider2 => slider2 && setNav2(slider2)}>
          {images.map(({ elements, id }, i: number) => {
            if (!elements) return
            const { single_image, caption } = elements
            return (
              <div className="fr-gallery-slider__slide" key={id}>
                <Image
                  image={single_image.value[0]}
                  alt={single_image.value[0]?.description ?? ""}
                  className="gallery__image"
                />
                <div className="fr-gallery-slider__caption-container" style={{maxWidth: `${single_image.value[0]?.width}px`}}>
                  {caption?.value && <span className="fr-gallery-slider__caption">{caption.value}</span>}
                  <span className="fr-gallery-slider__inner-counter">
                    {i + 1} of {images.length}
                  </span>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
      <div className="fr-gallery-slider__mobile-controller">
        <Slider asNavFor={nav2} {...mobileSliderSettings} ref={slider1 => slider1 && setNav1(slider1)}>
          {images.map(({ elements, id }, i: number) => {
            if (!elements) return
            return (
              <span key={id} className="fr-gallery-slider__outter-counter">{i + 1} of {images?.length}</span>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}
