import React from "react"
import { graphql } from "gatsby"
import { TabbedContainer } from "components/tabs/TabbedContainer"
import { Tabs } from "components/tabs/Tabs"
import { TabbedContent } from "./TabbedContent"
import { TabContentBlockItem } from "./tabbed-content-block-types"
import "./tabbed-content-banner.module.scss"
import { useWindowDimensions } from "hooks/useWindowDimensions"
import { TabbedContentSlider } from "./TabbedContentSlider"

interface TabbedContentBannerProps {
  heading?: string
  subHeading?: string
  content: TabContentBlockItem[]
  hasAltBackgroundColor: boolean
}

export const TabbedContentBlockBanner = ({
  heading,
  subHeading,
  content,
  hasAltBackgroundColor,
}: TabbedContentBannerProps) => {
  const { size } = useWindowDimensions()
  const isLaptop = size >= 1366

  const alternateBackground = hasAltBackgroundColor
    ? " alternate-background-color"
    : ""

  const isAsideColumn = content?.length > 4 ? true : false
  const bodyModifier = content?.length > 4 ? "--row" : "--column"
  const styleSubHeadingAsIntroParagraph =
    !!subHeading && subHeading.length > 150
  return (
    <div
      className={`fr-tabbed-content-block u-content-block-padding${alternateBackground}`}
    >
      <div className="fr-container">
        {heading && (
          <div className={`text-content-block__title`}>
            <h2>{heading}</h2>
            {subHeading && (
              <>
                {styleSubHeadingAsIntroParagraph ? (
                  <p>{subHeading}</p>
                ) : (
                  <h3>{subHeading}</h3>
                )}
              </>
            )}
          </div>
        )}
        <div className={`fr-tabbed-content-block__body${bodyModifier}`}>
          {isLaptop && (
            <TabbedContainer allContent={content}>
              {isAsideColumn ? (
                <Tabs
                  showSliderOnMobile={false}
                  hasSliderIndicatorBar
                  sliderBarDirection="vertical"
                  className="fr-cb-tabs-column"
                />
              ) : (
                <Tabs
                  showSliderOnMobile={false}
                  showTabTitleIcon
                  hasSliderIndicatorBar
                  className="fr-cb-tabs-row"
                />
              )}
              <div className="fr-tabbed-content-block__body-tab-content">
                <TabbedContent />
              </div>
            </TabbedContainer>
          )}
          {!isLaptop && (
            <TabbedContentSlider
              content={content}
              hasAltBackgroundColor={hasAltBackgroundColor}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment TabbedContentBannerFragment on kontent_item_tabbed_content_banner {
    id
    system {
      type
    }
    elements {
      heading {
        value
      }
      sub_heading {
        value
      }
      alt_background_color {
        value {
          codename
        }
      }
      tabbed_content {
        modular_content {
          ... on kontent_item_tab {
            id
            elements {
              tab_title {
                value
              }
              svg_icon {
                value
              }
              content_block {
                modular_content {
                  ...ContentBlockFragment
                  ...ServiceFragment
                }
              }
            }
          }
        }
      }
    }
  }
`
